.footer-container {
  display: flex;
  flex-direction: column;
}

.container {
  flex: 1;
}

.footer-section {
  color: #fff; /* 文字颜色 */
}

.footer-links a {
  color: #fff; /* 链接颜色 */
}

.footer-links a:hover {
  color: #007bff; /* 悬停时链接颜色 */
}

.footer-title {
  color: #fff; /* 标题颜色 */
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: 10px;
}

.copyright {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 10px 0;
  color: #fff; /* 文字颜色 */
}

.footer-link {
  /*color: #fff; !* 链接颜色 *!*/
  text-decoration: none; /* 去除链接的下划线 */
  color: inherit; /* 继承父元素的颜色，确保链接颜色与父元素一致 */
}

.footer-link:hover {
  color: #007bff; /* 悬停时链接颜色 */
}
