.navbar {
  display: flex;
  align-items: center;
  gap: 20px;
}
.container {
  flex: 1;
}
.change-lang {
  height: 24px;
  margin-right: 24px;
}