.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* 修改为垂直布局 */
}

.modal-container button {
  margin-top: 10px;
}

.modal-container h2 {
  margin-bottom: 20px;
  color: #333;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 70%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
}

#submitBtn,
#cancelBtn {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  bottom: 50px;
}

#cancelBtn {
  background-color: #ddd;
  color: #333;
  margin-right: 0; /* 移除取消按钮的右侧边距 */
  margin-top: 10px; /* 添加取消按钮的顶部边距 */
}
