.custom-select {
  position: relative;
  width: 150px; /* 根据需要设置宽度 */
}

.select-wrapper {
  position: relative;
  width: 100%;
}

.select-arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #fff; /* 箭头颜色 */
}

.change-lang {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: transparent;
  color: #fff; /* 未选中状态下文字颜色 */
  font-size: 16px;
  outline: none;
  cursor: pointer;
}

.change-lang option {
  background-color: #333; /* 选项背景颜色 */
  color: #fff; /* 选项文字颜色 */
}

.change-lang:hover,
.change-lang:focus,
.change-lang:active {
  border-color: #007bff;
  background-color: #333; /* 悬停、焦点和活动状态下的背景颜色 */
  color: #fff; /* 悬停、焦点和活动状态下的文字颜色 */
}

/* 悬停、焦点和活动状态下的箭头颜色 */
.select-wrapper:hover .select-arrow,
.select-wrapper:focus .select-arrow,
.select-wrapper:active .select-arrow {
  color: #007bff;
}

/* 选中状态下的文字颜色 */
.change-lang:checked {
  color: #fff;
}
